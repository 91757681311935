@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --channel-primary: 32 155 191;
  --channel-primary6: 29 139 171;
  --channel-primary7: 25 124 152;

  --channel-border: 220 220 220;

  --channel-background: 255  255 255;
  
  --channel-light: 246 250 253;
  
  --channel-light-dark: 186 230 242;

  --channel-secondary: 111 109 121;
  --channel-in-primary: 255  255 255;
  --channel-text: 1 1 1;
}

body, #root {
  min-height: 100vh;
  background: rgb(var(--channel-light));
}

* {
  font-size: 12px;
}

.linear-gradient {
  background: transparent linear-gradient(180deg, rgb(var(--channel-primary)) 0%, #104E60 100%) 0% 0% no-repeat padding-box;
}

input::placeholder {
  color: rgb(var(--channel-secondary));
}